<template>
	<div class="container text-page-content">
		<h1>{{ content.name }}</h1>
		<div v-html="content.raw"></div>
		<div v-if="activePage === 'shipping'" class="shipping-map">
			<iframe src="https://dev.dominikpateliotis.eu/gibon/">Mapa s přehledem doručování - Gibon</iframe>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "../../event-bus";

	export default {
		name: "TextPage",
		metaInfo() {
			return {
				title: this.content.name || null,
				meta: [{
					name: "description",
					content: this.content.stripped || null
				}]
			};
		},
		created() {
			EventBus.$emit("changeTheme", "white");
			this.loadPage();
		},
		watch: {
			$route() {
				this.loadPage();
			}
		},
		data() {
			return {
				content: {},
				apiDict: {
					"/obchodni-podminky": "tac",
					"/gdpr": "gdpr",
					"/reklamacni-rad": "refund",
					"/doprava": "shipping",
          "/cookies": "cookies",
				},
				activePage: null
			};
		},
		methods: {
			loadPage() {
				EventBus.$emit("loading", true);
				this.activePage = this.apiDict[this.$route.path];
				this.$api.loadText(this.activePage).then(data => {
					this.content = data;
					EventBus.$emit("loading", false);
				});
			}
		}
	}
</script>